<template>
    <v-card flat tile>
        <v-card-title class="secondary white--text text-h5">
            Liste des vins
            <v-spacer />
            <v-btn class="mx-2" dark color="indigo" @click="creervin">
                <v-icon dark>
                    mdi-plus
                </v-icon>
                <div>Ajouter un vin et son code QR</div>
            </v-btn>
        </v-card-title>
        <v-container fluid class="fill-height">
            <v-row class="child-flex">
                <template>
                    <v-data-table id="tablelistevins" :key="componentKey" :headers="headers" :items="vins"
                        :item-key="vins.UUID" :search="search" :items-per-page="15" :height="height" fixed-header
                        @click:row="(item) => voirvin(item)">
                        <template v-slot:top>
                            <v-toolbar class="pt-2">
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="search" label="Rechercher" />
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.photovin="{ item }">
                            <img width="128" :src="`${item.photovin}`">
                        </template>
                        <!-- <template v-slot:item.etiquetteface="{ item }">
                            <img width="128" :src="`${item.etiquetteface}`">
                        </template>
                        <template v-slot:item.etiquettedos="{ item }">
                            <img width="128" :src="`${item.etiquettedos}`">
                        </template> -->
                        <!-- <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="voirvin(item)">
                                mdi-eye
                            </v-icon>
                        </template> -->
                    </v-data-table>
                </template>
            </v-row>
            <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
                {{ result }}
            </v-snackbar>
        </v-container>
    </v-card>
</template>

<script>
import eventBus from '@/eventBus';

export default {
    name: 'ListeVins',
    data: () => ({
        componentKey: 0,
        search: '',
        color: '',
        result: '',
        showResult: false,
        dialog: false,
        dialogDelete: false,
        nbqrcodedispo: 0,
        height: 900,
        headers: [
            {
                text: 'Photo',
                sortable: false,
                align: 'left',
                value: 'photovin',
                width: '150px'
            },
            // {
            //     text: 'Etiquette',
            //     sortable: false,
            //     align: 'left',
            //     value: 'etiquetteface',
            //     width: '150px'
            // },
            // {
            //     text: 'Contre-étiquette',
            //     sortable: false,
            //     align: 'left',
            //     value: 'etiquettedos',
            //     width: '150px'
            // },
            {
                text: 'Nom du vin',
                value: 'nomduvin',
            },
            {
                text: 'Millésime',
                value: 'millesime',
            },
            {
                text: 'Appellation',
                value: 'appellation',
            },
            {
                text: 'Couleur',
                value: 'couleur',
            },
            {
                text: 'Numéro de lot',
                value: 'numerolot',
            },
            // {
            //     text: 'Actions',
            //     value: 'actions',
            //     sortable: false
            // }
        ],
        vins: []
    }),
    async mounted() {
        eventBus.$on('refreshlistvins', () => {
            this.listevins();
            console.log('refreshlistvins triggered!');
        });

        this.height = document.querySelector('.v-main__wrap').clientHeight - 200;
        this.uuidvin = this.$route.params.uuidvin;
        if (this.uuidvin !== ':uuidvin') {
            this.search = this.uuidvin;
        }
        await this.getappellations();
        await this.getcepages();
        await this.getcouleur();
        await this.listevins();
    },
    beforeDestroy() {
        // removing eventBus listener
        eventBus.$off('refreshlistvins');
    },
    methods: {
        async getnbqrcodedispo() {
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', this.$store.getters.token);
            const url = `${this.$config.API_ENDPOINT}/4DACTION/ksf_web_refreshqrcodedispo`;
            const response = await this.$axios.post(url, bodyFormData);
            this.nbqrcodedispo = response.data.nbqrcodedispo;
        },
        creerindexappellation() {
            this.appellationarridx = {};
            for (let i = 0; i < this.arrappellation.length; i += 1) {
                this.appellationarridx[this.arrappellation[i].UUID] = this.arrappellation[i].Appellation;
            }
        },
        rechercheappelationParUUID(uuidRecherche) {
            return this.appellationarridx[uuidRecherche] || uuidRecherche;
        },
        voirvin(item) {
            this.$router.push({
                path: `/visuetiquettevin/${item.UUID}?cb=${Date.now()}`
            });
        },
        async creervin() {
            await this.getnbqrcodedispo();
            if (this.nbqrcodedispo > 0) {
                this.$router.push('/creervin');
            } else {
                this.showResult = true;
                this.color = 'red';
                this.result = 'Vous n\'avez plus de code QR disponible, Merci de cliquer sur la boutique pour en acheter.';
            }
        },
        async getmillesimes() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallmillesimes`;
            await vm.$axios.get(url).then((response) => {
                this.arrmillesime = response.data;
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getappellations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallappellations`;
            await vm.$axios.get(url).then((response) => {
                vm.arrappellation = response.data;
                vm.arrappellationCopy = [...this.arrappellation];
                vm.creerindexappellation();
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcepages() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallcepages`;

            await vm.$axios.get(url).then((response) => {
                this.arrcepage = response.data;
                this.arrcepageCopy = [...this.arrcepage];
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcouleur() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getcouleur`;
            const response = await vm.$axios.get(url);
            vm.arrcouleurs = response.data.couleur;
            vm.arrcouleursCopy = JSON.parse(JSON.stringify(vm.arrcouleurs));
        },
        async listevins() {
            const vm = this;
            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', this.$store.getters.token);
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallwines?cb=${Date.now()}`;
            await vm.$axios.post(url, bodyFormData).then((response) => {
                const vinstmp = response.data;
                for (let i = 0; i < vinstmp.length; i += 1) {
                    vinstmp[i].photovin = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vinstmp[i].UUID}&type=photovin&vn_Taille=256&cb=${Date.now()}`;
                    // vinstmp[i].etiquetteface = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vinstmp[i].UUID}&type=etiquetteface&vn_Taille=256&cb=${Date.now()}`;
                    // vinstmp[i].etiquettedos = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_get_image/?vuuid=${vinstmp[i].UUID}&type=etiquettedos&vn_Taille=256&cb=${Date.now()}`;

                    const searchresponse = vm.rechercheappelationParUUID(vinstmp[i].uuid_appellation);
                    if (searchresponse !== -1) {
                        vinstmp[i].appellation = searchresponse;
                    }
                }
                vm.vins = vinstmp;
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        }
    }
};
</script>
<style>
#tablelistevins tbody tr :hover {
    cursor: pointer;
}
</style>
